export const SESSION_KEY = "conductify-ai-chat-session-key";
export const ACCEPT_ATTRIBUTE = ".xlsx,.pdf,.doc,.jpg,.jpeg,.png,.gif,.csv";
export const MAX_FILE_SIZE = 10000000; // 10MB
export const SUPPORTED_MIME_TYPES = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
  "application/pdf", // pdf
  "application/msword", // doc
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/gif",
  "text/csv",
];


export const INTEGRATION_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  DISABLED: "disabled",
};