import { Message } from "../types/Message";
import remarkGfm from "remark-gfm";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import moment from "moment";
import { isMobileDevice } from "../utils";
import { FileOutlined, CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useState } from "react";

const UserMessage = ({ message }: { message: Message }) => {
  const markdownText = message.content
    ?.replace(/\\n/gi, "\n")
    .replace(/\n/gi, "<br/>");

  // Add state for image modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState<{
    url: string;
    name?: string;
  } | null>(null);

  // Functions to handle modal
  const showModal = (image: { url: string; name?: string }) => {
    setSelectedImage(image);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="flex justify-end my-2">
      <style>
        {`
          .image-viewer-modal .ant-modal-content {
            background-color: rgba(0, 0, 0, 0.85);
            padding: 0;
          }
          .image-viewer-modal .ant-modal-body {
            overflow: none !important;
          }
        `}
      </style>
      <div className="flex flex-col items-end">
        <div className="flex flex-col items-end">
          {/* Message content */}
          {markdownText && markdownText.trim() !== "" && (
            <div className="flex items-end w-auto bg-blue-500 rounded-xl rounded-br-none max-w-80">
              <div className="p-2">
                <div
                  className={`text-white ${
                    isMobileDevice() ? "text-base" : "text-sm"
                  }`}
                  style={{
                    wordBreak: "break-word",
                  }}
                >
                  <Markdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                  >
                    {markdownText + ""}
                  </Markdown>
                </div>
              </div>
            </div>
          )}
          {message.attachments && message.attachments.length > 0 && (
            <div className="space-y-2 w-full mb-2 mt-1">
              {message.attachments.map((file, index) => (
                <div key={index} className="flex justify-end">
                  {file.type?.startsWith("image/") ? (
                    <div
                      onClick={() => showModal(file)}
                      className="rounded-xl rounded-br-none overflow-hidden cursor-pointer"
                      style={{ maxWidth: "320px" }}
                    >
                      <img
                        src={file.url}
                        alt={file.name || "image"}
                        className="w-full object-cover rounded-xl rounded-br-none"
                        style={{ maxHeight: "280px" }}
                      />
                    </div>
                  ) : (
                    <a
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center bg-gray-100 rounded-xl rounded-br-none p-3 hover:bg-gray-200 transition-colors"
                    >
                      <FileOutlined className="text-gray-500 mr-2 text-xl" />
                      <div className="text-sm text-gray-700 text-wrap">
                        {file.name || "File"}
                      </div>
                    </a>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="flex items-end w-auto max-w-80">
          <div
            className={`text-gray-500 pt-1 select-none ${
              isMobileDevice() ? "text-sm" : "text-xs"
            }`}
          >
            {moment(message.createdAt).fromNow()}
          </div>
        </div>
      </div>

      {/* Image Viewer Modal */}
      <Modal
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        centered
        width="auto"
        styles={{
          body: {
            padding: 0,
            margin: 0,
            overflow: "hidden",
            backgroundColor: "transparent",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          mask: { backgroundColor: "rgba(0, 0, 0, 0.8)" },
          content: {
            backgroundColor: "transparent",
            boxShadow: "none",
            padding: 0,
            margin: 0,
            maxWidth: "none",
          },
        }}
        className="image-viewer-modal"
      >
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
            backgroundColor: "transparent",
          }}
        >
          {selectedImage && (
            <>
              <img
                src={selectedImage.url}
                alt={selectedImage.name || "Full size image"}
                style={{
                  maxWidth: "95vw",
                  maxHeight: "95vh",
                  objectFit: "contain",
                }}
              />
              <CloseOutlined
                onClick={handleCancel}
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                  padding: "6px",
                  borderRadius: "50%",
                  cursor: "pointer",
                  zIndex: 1000,
                  fontSize: isMobileDevice() ? "8px" : "12px",
                }}
              />
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default UserMessage;
