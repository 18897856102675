import { ApiResponse, BaseApiService } from "./base.service";
import qs from "qs";

export class MessageAPIFactory extends BaseApiService {
  getMessages(query: any): Promise<ApiResponse> {
    return this.post(`/integration-standalone/history`, query);
  }

  sendMessages(inputs: any): Promise<ApiResponse> {
    return this.post(`/integration-standalone/completion-webhook`, inputs);
  }

  getBotInfo(
    uuid: string,
    integrationUuid: string | undefined
  ): Promise<ApiResponse> {
    if (!integrationUuid) {
      return this.get(
        `/integration-standalone/agent-info?agentUuid=${uuid}`
      );
    }
    return this.get(
      `/integration-standalone/agent-info?agentUuid=${uuid}&integrationUuid=${integrationUuid}`
    );
  }
  uploadFile(file: File, botUuid: string, threadId: string) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("botUuid", botUuid);
    formData.append("threadId", threadId);

    return this.post("/upload/chat-attachment", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export const MessageService = new MessageAPIFactory();
