import Markdown from "react-markdown";
import { Message } from "../types/Message";
import remarkGfm from "remark-gfm";
import moment from "moment";
import rehypeRaw from "rehype-raw";
import { isMobileDevice } from "../utils";
import { useState } from "react";
import { FileOutlined, CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";

const AssistantMessage = (
  { message }: { message: Message } = { message: {} as Message }
) => {
  const markdownText = message.content;
  const isClearContext = message.content === "CLEAR_CONTEXT";

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState<{
    url: string;
    name?: string;
  } | null>(null);

  // Functions to handle modal
  const showModal = (image: { url: string; name?: string }) => {
    setSelectedImage(image);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Extract image URLs from the message content
  // const imageUrls = message.content?.match(/(https?:\/\/[^\s]+(?=\.(?:png|jpg|jpeg|gif)|(?=\?|\#)))/gi) || [];

  // console.log(message.content, imageUrls)

  return isClearContext ? (
    <div className="flex justify-center w-4/5 relative p-1 my-8 h-5 mx-auto cursor-default select-none">
      <div className="border-b border-dashed border-gray-300  w-1/2"></div>
      <div
        style={{
          top: "14px",
        }}
        className="px-2 text-sm text-gray-500  absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      >
        Context Cleared
      </div>
      <div className="border-b border-dashed border-gray-300  w-1/2"></div>
    </div>
  ) : (
    <div className="flex items-end m-2">
      <div className="flex flex-col items-start my-1 sm:w-3/4 md:w-3/4">
        {/* <div className="text-xs text-gray-100 dark:text-gray-200 pb-1">
                    Assistant
                </div> */}
        {markdownText && markdownText.trim() !== "" && (
          <div
            className={`p-3 text-gray-700 bg-gray-200 rounded-2xl rounded-bl-none ${
              isMobileDevice() ? "text-base" : "text-sm"
            }`}
            style={{
              wordBreak: "break-word",
            }}
          >
            <Markdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
              components={{
                a: ({ ...props }) => (
                  <a
                    {...props}
                    target="_blank"
                    className="text-blue-600 hover:underline"
                  />
                ),
                p: ({ ...props }) => <p {...props} className="mb-0" />,
                h1: ({ node, ...props }) => (
                  <h1 {...props} className="text-2xl mb-2 mt-3 font-semibold" />
                ),
                h2: ({ node, ...props }) => (
                  <h2 {...props} className="text-xl mb-1 mt-3 font-semibold" />
                ),
                h3: ({ node, ...props }) => (
                  <h3
                    {...props}
                    className="text-base mb-1 mt-3 font-semibold"
                  />
                ),
                ul: ({ node, ...props }) => (
                  <ul {...props} className="list-disc pl-5 mb-3" />
                ),
                ol: ({ node, ...props }) => (
                  <ol {...props} className="list-decimal pl-5 mb-3" />
                ),
                li: ({ node, ...props }) => <li {...props} className="mb-1" />,
              }}
            >
              {markdownText}
            </Markdown>
          </div>
        )}
        {message.attachments && message.attachments.length > 0 && (
          <div className="space-y-2 w-full mb-2 mt-1">
            {message.attachments.map((file, index) => (
              <div key={index} className="flex justify-start">
                {file.type?.startsWith("image/") ? (
                  <div
                    onClick={() => showModal(file)}
                    className="rounded-xl rounded-bl-none overflow-hidden cursor-pointer"
                    style={{ maxWidth: "320px" }}
                  >
                    <img
                      src={file.url}
                      alt={file.name || "image"}
                      className="w-full object-cover rounded-xl rounded-bl-none"
                      style={{ maxHeight: "280px" }}
                    />
                  </div>
                ) : (
                  <a
                    href={file.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center bg-gray-100 rounded-xl rounded-bl-none p-3 hover:bg-gray-200 transition-colors"
                    // style={{ maxWidth: "320px" }}
                  >
                    <FileOutlined className="text-gray-500 mr-2 text-xl" />
                    <div className="text-sm text-gray-700 text-wrap">
                      {file.name || "File"}
                    </div>
                  </a>
                )}
              </div>
            ))}
          </div>
        )}
        <div
          className={`text-gray-500 pt-1 select-none ${
            isMobileDevice() ? "text-sm" : "text-xs"
          }`}
        >
          {moment(message.botResponseAt ?? message.updatedAt).fromNow()}
        </div>
      </div>
      {/* Image Viewer Modal */}
      <Modal
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        centered
        width="auto"
        styles={{
          body: {
            padding: 0,
            margin: 0,
            overflow: "hidden",
            backgroundColor: "transparent",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          mask: { backgroundColor: "rgba(0, 0, 0, 0.8)" },
          content: {
            backgroundColor: "transparent",
            boxShadow: "none",
            padding: 0,
            margin: 0,
            maxWidth: "none",
          },
        }}
        className="image-viewer-modal"
      >
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
            backgroundColor: "transparent",
          }}
        >
          {selectedImage && (
            <>
              <img
                src={selectedImage.url}
                alt={selectedImage.name || "Full size image"}
                style={{
                  maxWidth: "95vw",
                  maxHeight: "95vh",
                  objectFit: "contain",
                }}
              />
              <CloseOutlined
                onClick={handleCancel}
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                  padding: "6px",
                  borderRadius: "50%",
                  cursor: "pointer",
                  zIndex: 1000,
                  fontSize: isMobileDevice() ? "8px" : "12px",
                }}
              />
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AssistantMessage;
