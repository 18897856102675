import { Button, Input, Tooltip, Typography, Upload } from "antd";

import { Modal } from "antd";
import { MessageService } from "../services/chat.service";
import { isMobileDevice } from "../utils";
import { message as antMessage } from "antd";
import {
  PlusOutlined,
  LoadingOutlined,
  FileOutlined,
  SendOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import {
  ACCEPT_ATTRIBUTE,
  MAX_FILE_SIZE,
  SUPPORTED_MIME_TYPES,
} from "../constants";

const UploadFile = ({
  shouldShowTypingMessage,
  setShouldShowTypingMessage,
  onUserMessageAdded,
  sendMessage,
  botUuid,
  threadId,
  disabled = false,
}: {
  shouldShowTypingMessage: boolean;
  setShouldShowTypingMessage: (shouldShowTypingMessage: boolean) => void;
  onUserMessageAdded: (message: string, files: any[]) => Promise<void>;
  sendMessage: () => void;
  botUuid: string;
  threadId: string;
  disabled?: boolean;
}) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const onkeydownFile = async (e: any) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleOk();
      e.preventDefault();
    }
  };

  const handlePreview = (file: any) => {
    if (file && file.url) {
      const url = typeof file.url === "string" ? file.url : String(file.url);
      window.open(url, "_blank");
    }
  };

  const handleUploadFile = async (info: any) => {
    try {
      const result = await MessageService.uploadFile(
        info.file,
        botUuid,
        threadId
      );

      if (result.data && result.data.data) {
        const fileUrl = result.data.data;
        const successFile = {
          uid: info.file.uid,
          name: info.file.name,
          status: "done",
          size: info.file.size,
          type: info.file.type,
          url: encodeURI(fileUrl),
          thumbUrl: encodeURI(fileUrl),
        };
        setFileList([successFile]);
        setIsModalVisible(true);
      } else {
        const failedFile = {
          ...info.file,
          status: "error",
          percent: 0,
          error: result.data?.message || "File upload failed",
        };
        setFileList([failedFile]);
        antMessage.error("File upload failed. Please try again.");
      }
    } catch (error) {
      // Handle error
      const failedFile = {
        ...info.file,
        status: "error",
        percent: 0,
        error: "Failed to upload file",
      };
      setFileList([failedFile]);
      antMessage.error("File upload failed. Please try again.");
    }
  };

  const handleFileChange = ({ fileList }: any) => {
    setFileList(fileList);
    if (fileList.length > 0) {
      setIsModalVisible(true);
    }
  };

  const handleOk = async () => {
    // Check if any files failed to upload
    const hasFailedUploads = fileList.some((file) => file.status === "error");

    if (hasFailedUploads) {
      antMessage.error(
        "Cannot send message with failed uploads. Please remove them and try again."
      );
      return;
    }

    setIsModalVisible(false);
    setShouldShowTypingMessage(true);
    const files = fileList.map((file) => ({
      ...file,
      name: file.name,
    }));
    await onUserMessageAdded(message, files);
    setMessage("");
    setFileList([]);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setMessage("");
    setFileList([]);
  };
  const uploadButton = (
    <button
      style={{
        border: "1px dashed #d9d9d9",
        padding: "20px",
        background: "none",
        borderRadius: "10%",
      }}
      type="button"
    >
      <PlusOutlined type="dashed" />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const beforeUpload = (file: any) => {
    if (file.size > MAX_FILE_SIZE) {
      antMessage.error(
        "File is too large. Maximum file size is approximately 10MB."
      );
      return Upload.LIST_IGNORE;
    }

    const isValidType = SUPPORTED_MIME_TYPES.includes(file.type);
    if (!isValidType) {
      antMessage.error(
        "File type not supported. Please upload a supported file type."
      );
      return Upload.LIST_IGNORE;
    }

    return true;
  };

  return (
    <div className="flex items-center">
      <Upload
        className="custom-upload-button mr-2"
        fileList={fileList}
        onChange={handleFileChange}
        showUploadList={false}
        customRequest={handleUploadFile}
        onPreview={handlePreview}
        onRemove={(file) => {
          setFileList(fileList.filter((f) => f.uid !== file.uid));
        }}
        itemRender={(originNode, file, actions) => {
          // For images, render a custom preview with file name
          if (file.type?.startsWith("image/")) {
            return (
              <div style={{ marginBottom: 8, position: "relative" }}>
                <img
                  src={file.url}
                  alt={file.name}
                  style={{ maxWidth: "100%", maxHeight: 200 }}
                />
                <div
                  style={{
                    marginTop: 5,
                    fontSize: "14px",
                    color: "#333",
                  }}
                >
                  {file.name}
                </div>
                {file.status === "uploading" && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: "rgba(255, 255, 255, 0.7)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <LoadingOutlined
                      style={{ fontSize: 24, color: "#1890ff" }}
                    />
                    <div style={{ marginTop: 8, color: "#1890ff" }}>
                      Uploading...{" "}
                      {file.percent ? Math.round(file.percent) + "%" : ""}
                    </div>
                  </div>
                )}
                {file.status === "error" && (
                  <div
                    style={{
                      position: "absolute",
                      top: 5,
                      right: 5,
                      background: "#ff4d4f",
                      borderRadius: "50%",
                      width: 24,
                      height: 24,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                    }}
                  >
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="close"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                    </svg>
                  </div>
                )}
              </div>
            );
          }

          // For other file types that are uploading
          if (file.status === "uploading") {
            return (
              <div
                style={{
                  padding: "12px",
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  background: "#f5f5f5",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FileOutlined style={{ fontSize: 20, marginRight: 8 }} />
                <div style={{ flex: 1 }}>
                  <Typography.Text
                    style={{
                      fontSize: 14,
                      color: "#333",
                      width: "100%",
                    }}
                  >
                    {file.name}
                  </Typography.Text>
                  <div style={{ marginTop: 8 }}>
                    <div
                      style={{
                        height: 2,
                        background: "#e6e6e6",
                        borderRadius: 2,
                        overflow: "hidden",
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          background: "#1890ff",
                          width: `${file.percent || 0}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 4,
                      fontSize: 12,
                      color: "#1890ff",
                    }}
                  >
                    <LoadingOutlined style={{ marginRight: 4 }} />
                    Uploading{" "}
                    {file.percent ? Math.round(file.percent) + "%" : ""}
                  </div>
                </div>
              </div>
            );
          }

          // For other files, just return the original node
          return originNode;
        }}
        accept={ACCEPT_ATTRIBUTE}
        beforeUpload={beforeUpload}
      >
        <button
          disabled={shouldShowTypingMessage || disabled}
          onClick={sendMessage}
          className={`disabled:cursor-not-allowed disabled:text-slate-200 bg-gray-100 flex justify-center items-center text-gray-800 ${
            isMobileDevice() ? "mr-0 size-5" : "mr-0"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"
            />
          </svg>
        </button>
      </Upload>

      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Send"
        cancelText="Cancel"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{
          style: { display: "none" },
        }}
        style={{
          top: isMobileDevice() ? 180 : 250,
        }}
        width={isMobileDevice() ? "80%" : 500}
        styles={{
          body: {
            maxHeight: isMobileDevice() ? "60vh" : "500px",
            overflowY: "auto",
            padding: "0px",
          },
        }}
        footer={
          <div className="flex items-center w-full flex-end">
            <span className="text-xs text-gray-400 whitespace-nowrap">
              Supported Files
              <Tooltip title={ACCEPT_ATTRIBUTE.split(",").join(", ")}>
                <QuestionCircleOutlined
                  size={12}
                  className="text-gray-400 ml-1 mr-2"
                />
              </Tooltip>
              Maximum File Size: {MAX_FILE_SIZE / 1000000}MB
            </span>
          </div>
        }
      >
        {/* File preview section */}
        <div className="file-preview mb-4">
          {fileList.map((file) => (
            <div
              key={file.uid}
              style={{
                marginBottom: 12,
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: file.type?.startsWith("image/")
                  ? "center"
                  : "flex-start",
              }}
            >
              {file.type?.startsWith("image/") ? (
                <div style={{ position: "relative" }}>
                  <img
                    src={file.url}
                    alt={file.name}
                    style={{
                      maxWidth: "100%",
                      maxHeight: 200,
                      marginBottom: 8,
                      marginTop: 24,
                    }}
                  />
                  {file.status === "uploading" && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: "rgba(255, 255, 255, 0.7)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <LoadingOutlined
                        style={{ fontSize: 24, color: "#1890ff" }}
                      />
                      <div style={{ marginTop: 8, color: "#1890ff" }}>
                        Uploading...{" "}
                        {file.percent ? Math.round(file.percent) + "%" : ""}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    padding: "12px",
                    border: "1px solid #d9d9d9",
                    borderRadius: "4px",
                    background: "#f5f5f5",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginRight: 8 }}>
                      {file.status === "uploading" ? (
                        <LoadingOutlined style={{ fontSize: 20 }} />
                      ) : (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8L14 2Z"
                            stroke="#666"
                            strokeWidth="2"
                            fill="#e6e6e6"
                          />
                        </svg>
                      )}
                    </div>
                    <Typography.Text style={{ flex: 1 }}>
                      {file.name}
                    </Typography.Text>
                  </div>
                  {file.status === "uploading" && (
                    <div style={{ marginTop: 8 }}>
                      <div
                        style={{
                          height: 2,
                          background: "#e6e6e6",
                          borderRadius: 2,
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            height: "100%",
                            background: "#1890ff",
                            width: `${file.percent || 0}%`,
                          }}
                        ></div>
                      </div>
                      <div
                        style={{ marginTop: 4, fontSize: 12, color: "#1890ff" }}
                      >
                        Uploading{" "}
                        {file.percent ? Math.round(file.percent) + "%" : ""}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* {file.status !== "uploading" && (
                <Button
                  style={{
                    border: "1px solid #ff4d4f",
                    background: "rgba(255, 255, 255, 0.7)",
                    borderRadius: "50%",
                    width: 28,
                    height: 28,
                    padding: 0,
                    position: "absolute",
                    top: 8,
                    right: 8,
                  }}
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    console.log("file", file);
                    setFileList(fileList.filter((f) => f.uid !== file.uid));
                  }}
                />
              )} */}
              {file.status === "error" && (
                <div
                  style={{ color: "#ff4d4f", fontSize: "12px", marginTop: 4 }}
                >
                  Upload failed
                </div>
              )}
            </div>
          ))}
        </div>
        {fileList.length === 0 && (
          <div className="flex justify-center my-4">
            <Upload
              className="custom-upload-button"
              showUploadList={false}
              customRequest={handleUploadFile}
              accept={ACCEPT_ATTRIBUTE}
              beforeUpload={beforeUpload}
            >
              {uploadButton}
            </Upload>
          </div>
        )}
        {/* <div className="text-base font-medium mt-3 mb-1">Caption</div> */}
        <div className="bottom-border-only relative">
          <Input.TextArea
            className="bottom-border-only"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Add a caption..."
            rows={3}
            onKeyDown={onkeydownFile}
            autoSize={{ minRows: 1, maxRows: 3 }}
            style={{
              paddingRight: "40px",
              overflow: "hidden",
              resize: "none",
            }}
          />
          <div
            className="absolute right-0 bottom-0 h-full flex items-center pr-2"
            style={{
              background:
                "linear-gradient(20deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%)",
              // width: "38px",
            }}
          >
            <button
              onClick={handleOk}
              disabled={fileList.length === 0 && !message}
              className="bg-transparent border-none cursor-pointer disabled:cursor-not-allowed disabled:opacity-50 hover:opacity-75 transition-opacity duration-200 z-10"
              style={{ outline: "none" }}
            >
              <SendOutlined
                style={{
                  fontSize: "18px",
                  color:
                    fileList.length === 0 && !message ? "#d9d9d9" : "#1890ff",
                }}
                className="hover:text-blue-600 transition-colors duration-200"
              />
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UploadFile;
